import { fetchFromAPI, getRequestOptions } from '~/lib/baseApi'
import { buildQueryString } from '~/lib/buildQueryString'
import { GetCompaniesParams, GetCompaniesResponse } from '~/feature/companies/types'

const getCompanies = async (
  params: GetCompaniesParams,
): Promise<GetCompaniesResponse> => {
  const queryString = buildQueryString(params)

  return await fetchFromAPI<Promise<GetCompaniesResponse>>({
    endpoint: '/companies',
    queryString: queryString,
    options: getRequestOptions(),
  })
}

export default getCompanies
